<template>
  <section class="add-amenities-slider">
    <div class="row flex-grow">
      
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title"> Edit Nearby Locations</h4>
            <!-- <p class="card-description"> About Avant Garde </p> -->
            <form class="forms-sample">
              <div class="row">
                <div class="col-md-6">
                  <div role="group" class="form-group">
                    <label class="d-block">Locations</label>
                    <div>
                      <input type="text" placeholder="Locations" class="form-control">
                    </div>
                  </div>
                  <div role="group" class="form-group">
                    <label class="d-block">Distance</label>
                    <div>
                      <input type="text" placeholder="Distance" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div role="group" class="form-group">
                    <label class="d-block">Image</label>
                    <picture-input ref="pictureInput" height="125" margin="0" accept="image/jpeg,image/png" size="10"
                      button-class="btn">
                    </picture-input>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn mr-2 btn-primary">Update</button>
            </form>
          </div>
        </div>
      </div>
     
    </div>
  </section>
</template>

<script>

import PictureInput from 'vue-picture-input'
export default {
  name: 'add-amenities-slider',
  components: {
    PictureInput
  },
  data() {
    return {
    };
  }
}
</script>

<style scoped></style>